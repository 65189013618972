import React, { useContext } from 'react';
import { Link } from 'gatsby';
import UserLinks from './UserLinks';
import ConfigContext from '../context/ConfigContext';
import './Footer.css';

function Footer() {
  const config = useContext(ConfigContext);

  const url = config.siteRss;
  const { copyright } = config;
  if (!copyright) {
    return null;
  }
  return (
    <footer className='footer bg-white relative pt-1 flex-shrink-0'>
      <div className='container mx-auto px-6'>
        <div className='mt-6 border-t-2 border-gray-300 flex flex-col items-center'>
          <div className='sm:w-2/3 text-center py-6'>
            <p className='text-sm text-black-700 font-bold mb-2'>
              {copyright} by <a href='https://thoughtfulram.com'>Thoughtful Ram</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
