import React from 'react';
import { Helmet } from 'react-helmet';
import config from '../../data/SiteConfig';
import './index.css';
import { ConfigProvider } from '../context/ConfigContext';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

export default function MainLayout({ children }) {
  return (
    <ConfigProvider>
      <div className='flex flex-col container py-4 mx-auto h-full'>
        <Helmet>
          <meta name='description' content={config.siteDescription} />
          <html lang='en' />
        </Helmet>
        <Navigation />
        {children}
        <Footer />
      </div>
    </ConfigProvider>
  );
}
