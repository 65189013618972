import React, { FunctionComponent } from 'react';
import { Link } from 'gatsby';

interface Props {}

const Navigation: FunctionComponent<Props> = () => (
  <div className='flex mb-8'>
    <Link to={'/'}>
      <img src='/logos/logo-192.png' width={48} height={48} alt='Thoughtful Ram' />
    </Link>
    <ul className='inline-flex items-center'>
      <li className='flex-1 px-8'>
        <Link to={'/'}>Posts</Link>
      </li>
      <li className='flex-1 px-8'>
        <Link to={'/contact'}>Contact</Link>
      </li>
    </ul>
  </div>
);

export default Navigation;
